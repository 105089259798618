<template>
  <div class="list__content">
    <div class="list__content-inner" id="list__content-inner">
      <table class="table table-visit table_overflow_hidden">
        <thead>
          <tr class="tr-visit">
            <th colspan="4">Дни недели</th>
            <th v-if="group == -1"></th>
            <th
              class="th-day"
              v-for="(item, index) in tableHeader"
              :key="index"
            >
              {{ item.format("dd") }}
            </th>
            <th v-if="displayLayout">Пропущено дней/ часов</th>
            <th v-if="displayLayout">
              Дни/ часы посещения, подлежащие отплате
            </th>
            <th v-if="displayLayout">Причины не посещения</th>
          </tr>
          <tr>
            <th>№</th>
            <th>Табельный номер</th>

            <th>Воспитанник</th>
            <th v-if="group == -1">Группа</th>
            <th />
            <th v-if="displayLayout">Плата по ставке</th>
            <th
              class="th-day"
              v-for="(item, index) in tableHeader"
              :key="index"
            >
              {{ item.format("DD") }}
            </th>
            <th v-if="displayLayout">Всего</th>
            <th v-if="displayLayout">В том числе засчитываемых</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="item"
            v-for="(item, index) in sortDataChildrenTable"
            :key="index"
          >
            <td>
              {{ index + 1 }}
            </td>
            <td>{{ item.child.number }}</td>

            <td class="td-align_left">
              {{ `${item.child.name} ` }}
            </td>
            <th v-if="group == -1">{{ item.child.children_group.name }}</th>
            <td></td>
            <td
              v-for="(el, index) in tableHeader"
              style="padding: 3px"
              :key="index"
              @click="displaySelect = true"
              :class="
                !(el.diff(moment(), 'day', true) <= 0 && toggleHandler)
                  ? item.visits.find(visit => !moment(visit.date).diff(el))
                    ? item.visits.find(visit => !moment(visit.date).diff(el))
                        .status === 1
                      ? `td-visited`
                      : item.visits.find(visit => !moment(visit.date).diff(el))
                          .status === 2
                      ? `td-sick`
                      : item.visits.find(visit => !moment(visit.date).diff(el))
                          .status === 0
                      ? `td-absent`
                      : ``
                    : ``
                  : ''
              "
            >
              <v-select
                :class="{ 'on-edit': toggleHandler }"
                style="width: 25px; margin: 0 !important; padding: 0; height: 40px"
                append-icon=""
                @input="
                  setValueSelect(
                    $event,
                    item.child.id,
                    el,
                    item.visits.find(visit => !moment(visit.date).diff(el))
                  )
                "
                :items="items"
                :value="
                  (() => {
                    const finded = item.visits.find(
                      visit => !moment(visit.date).diff(el)
                    );
                    return finded ? finded.status : null;
                  })()
                "
                v-if="el.diff(moment(), 'day', true) <= 0 && toggleHandler"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-end mt-6" v-if="toggleHandler === true">
        <slot name="buttonSubmit"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";

export default {
  name: "ChildVisitLogTable",
  data() {
    return {
      moment: moment,
      displaySelect: false,
      items: [
        { text: "П", value: 1 },
        { text: "Б", value: 2 },
        { text: "О", value: 0 }
      ]
    };
  },
  props: {
    tableHeader: {
      type: Array,
      required: true
    },
    dataChildrenTable: {
      type: Object,
      required: true
    },
    toggleHandler: {
      type: Boolean,
      required: true
    },
    displayLayout: {
      type: Boolean
    }
  },
  computed: {
    group() {
      return this.$store.getters.getChildrenVisitLog.groups;
    },
    sortDataChildrenTable() {
      const arrWithFullName = _.forEach(
        _.toArray(this.dataChildrenTable),
        function(elem) {
          elem.child.fullName = `${elem.child.last_name} ${elem.child.first_name} ${elem.child.middle_name}`;
        }
      );
      const sortByFullName = this.sortBy(arrWithFullName, "child.fullName");
      if (this.group == -1) {
        return this.sortBy(sortByFullName, "child.children_group.id");
      } else {
        return sortByFullName;
      }
    }
  },
  methods: {
    setValueSelect(status, childId, date, finded) {
      return this.$store.commit("setCreateChildrenVisitLog", {
        fieldName: "status",
        value: { status, childId, date, id: finded?.id }
      });
    },

    sortBy(arr, field) {
      return arr.sort(function(a, b) {
        let x = _.get(a, field);
        let y = _.get(b, field);
        return x < y ? -1 : x > y ? 1 : 0;
      });
    }
  }
};
</script>

<style>
.on-edit >>> div >>> .v-input__slot >>> .v-select__slot {
  padding: 2px !important;
}

.on-edit.v-input .v-select__slot {
  padding: 3px !important;
}
</style>
