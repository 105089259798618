<template>
  <div>
    <MainLayout v-if="displayLayout">
      <div class="list list_radius-all container list_overflow_visible">
        <div class="filter filter_padding">
          <div class="filter__inner">
            <form class="filter__form">
              <ValidationSelectField
                label="Месяц"
                :items="dayArr"
                v-model="currentDay"
              />
              <ValidationAutocompleteField
                label="Группа"
                v-model="group"
                :search-function="childrenGroupSearch"
              />

              <div class="flex-ver-center grid-column_span_3">
                <a
                  class="btn btn-check_white btn_color_green flex-right btn_right-margin"
                  @click="toggleHandler = true"
                  v-if="currentMomentDate.isSame(moment(), 'month') && group"
                  >Отметить пришедших</a
                >
                <v-spacer v-else />

                <v-btn
                  color="#6d778e"
                  style="color:white"
                  class="btn btn-unload ml-1"
                  @click="downloadExel"
                  v-if="group && currentDay"
                  :disabled="loadingBtn || sourceObject.results.length == 0"
                  :loading="loadingBtn"
                >
                  Выгрузить
                </v-btn>
              </div>
            </form>
            <h2 class="filter__subtitle filter__subtitle_margin-top my-8">
              {{ groupName }}
            </h2>

            <ChildVisitLogTable
              :toggle-handler="toggleHandler"
              :data-children-table="childTableData"
              v-if="group && currentDay"
              :table-header="daysOfMonth"
            >
              <template v-slot:buttonSubmit>
                <button class="btn btn_color_white mr-5" @click="clearVisitLog">
                  Отмена
                </button>
                <a class="btn btn-green" @click="submitVisited">
                  Сохранить
                </a>
              </template>
            </ChildVisitLogTable>
          </div>
        </div>
      </div>
    </MainLayout>
    <div v-if="displayLayout === false">
      <div class="d-flex justify-center my-7">
        <div class="text-align_center headText">
          <h5>ТАБЕЛЬ УЧЕТА ПОСЕЩАЕМОСТИ ВОСПИТАННИКОВ</h5>
          <h6>за {{ currentDay }}</h6>
          <div class="my-7">
            <v-text-field
              outlined
              label="Учереждение"
              :disabled="true"
              class="textField"
            ></v-text-field>
            <v-text-field
              outlined
              label="Структурное подразделение"
              single-line
              class="textField"
            ></v-text-field>
            <v-text-field
              outlined
              label="Вид расчета"
              single-line
              class="textField"
            ></v-text-field>
          </div>
          <div style="position: relative; right: 500px;">
            <h2 class="filter__subtitle filter__subtitle_margin-top my-8">
              {{ groupName }}
            </h2>
          </div>
        </div>

        <div class=" mt-5 d-flex">
          <div class="mr-5">
            <p>Форма по ОКУД</p>
            <p class="mt-3">Дата</p>
            <p class="mt-3">по СЖПО</p>
          </div>
          <div>
            <div class="table_border"></div>
            <div class="table_border"></div>
            <div class="table_border"></div>
            <div class="table_border"></div>
            <div class="table_border"></div>
            <div class="table_border"></div>
          </div>
        </div>
      </div>

      <ChildVisitLogTable
        :toggle-handler="toggleHandler"
        :data-children-table="childTableData"
        v-if="group && currentDay"
        :table-header="daysOfMonth"
        :displayLayout="true"
      />
    </div>
  </div>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import ValidationSelectField from "@/components/ValidationField/ValidationSelectField";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import {
  // getChildrenGroupByIdRequest,
  // getChildrenGroupsRequest,
  getKindergartensChildrenGroupsClearRequest
} from "@/helpers/api/childrenGroup";
import moment from "moment";
import ChildVisitLogTable from "@/components/tables/ChildVisitLogTable";
// import router from "@/router";
import excelJS from "exceljs";
import { saveAs } from "file-saver";
import _ from "lodash";
import exelCols from "@/helpers/dictionariesHelper/childrenVisitLog";

export default {
  name: "ChildrenVisitLogPage",
  components: {
    ChildVisitLogTable,
    ValidationAutocompleteField,
    ValidationSelectField,
    MainLayout
  },
  data() {
    return {
      loadingBtn: false,
      toggleHandler: false,
      displayLayout: true,
      startDate: moment("2019-01-01", "YYYY-MM"),
      newDate: moment(new Date()),
      moment: moment,
      childrenGroupSearch: async value => {
        const id = this.$store.getters.getCurrentOrganization;
        const childsLogs = (
          await getKindergartensChildrenGroupsClearRequest({
            query: { search: value },
            id: id
          })
        ).data.results;

        let childsLogsArr = [];
        if (this.currentRole == 21) {
          childsLogsArr = childsLogs
            .filter(el => el.teachers.includes(this.currentUserId))
            .map(el => ({
              text: el.name,
              value: el.id
            }));
        } else {
          childsLogsArr = childsLogs.map(el => ({
            text: el.name,
            value: el.id
          }));

          childsLogsArr.unshift({
            text: "Все группы",
            value: -1
          });
        }
        return childsLogsArr;
      },
      marks: {
        0: "О", // отсутствовал
        2: "Б", // болел
        1: "П" // присутствовал
      }
    };
  },
  computed: {
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    currentUserId() {
      return this.$store.getters.getUserId;
    },
    sourceObject() {
      return this.$store.getters.getChildrenVisitLogList;
    },
    KindergartenName() {
      return this.$store.getters.getKindergartenName.results.name;
    },
    childTableData() {
      return this.$store.getters.childTableData;
    },
    groupName: {
      get() {
        return this.$store.getters.getChildGroupName.nameGroup;
      }
    },
    group: {
      get() {
        return this.$store.getters.getChildrenVisitLog.groups;
      },
      set(newValue) {
        this.$store.commit("setChildrenVisitLogData", {
          fieldName: "groups",
          value: newValue
        });
        if (this.group && this.currentDay) {
          // this.$store.dispatch("fetchChildrenVisitLogByChildrenGroup");
          if (newValue == -1) {
            this.$store.dispatch("fetchAllChildrensInKindergarden");
          } else {
            this.$store.dispatch("testFetch");
            this.$store.dispatch("fetchChildrenGroupByIdGetName", this.group);
          }
        }
      }
    },
    dayArr: {
      get() {
        const arr = [];
        for (
          let decDate = this.newDate;
          decDate.diff(this.startDate) >= 0;
          decDate = this.newDate.subtract(1, "month")
        ) {
          const momentMoth = decDate.clone();
          const month = momentMoth.format("MMMM · YYYY");
          arr.push({
            text: month[0].toLocaleUpperCase() + month.slice(1),
            value: momentMoth.format("YYYY-MM")
          });
        }
        return arr;
      }
    },
    momentCurDay: {
      get() {
        return moment(this.currentDay, "YYYY-MM");
      }
    },
    currentDay: {
      get() {
        return this.$store.getters.getChildrenVisitLog.date;
      },
      set(newValue) {
        this.$store.commit("setChildrenVisitLogData", {
          fieldName: "date",
          value: newValue
        });
        if (this.group && this.currentDay) {
          // this.$store.dispatch("fetchChildrenVisitLogByChildrenGroup");
          this.$store.dispatch("testFetch");
        }
      }
    },
    currentMomentDate() {
      return moment(this.currentDay, "YYYY-MM");
    },
    daysOfMonth() {
      let daysInMonth = this.currentMomentDate.daysInMonth();
      const arrDays = [];

      while (daysInMonth) {
        const current = this.currentMomentDate.date(daysInMonth).clone();
        arrDays.unshift(current);
        daysInMonth--;
      }

      return arrDays;
    },
    urlQuery() {
      return {
        id: this.$route.params.id,
        query: this.$route.query
      };
    }
  },
  methods: {
    download() {
      this.displayLayout = false;
      this.$nextTick(() => {
        window.print();
        this.displayLayout = true;
      });
    },
    async downloadExel() {
      this.loadingBtn = true;
      const self = this;
      const maxCol = this.daysOfMonth.length + 8; // максимальная ширина таблицы

      const workbook = new excelJS.Workbook();
      workbook.creator = "oppen";
      workbook.lastModifiedBy = "oppen";
      workbook.created = new Date();
      workbook.modified = new Date();

      let sheet = workbook.addWorksheet(
        `${this.group == "-1" ? "Все группы" : this.groupName}`
      );

      const days = this.daysOfMonth.map(function(el) {
        return {
          numbers: moment(el._d).format("YYYY-MM-DD"),
          week: moment(el._d).format("dd")
        };
      });

      sheet.getRow(1).values = ["Форма 0504608 с. 1"];
      sheet.getRow(2).values = [];
      sheet.getRow(3).values = ["ТАБЕЛЬ"];
      sheet.getRow(4).values = ["УЧЕТА ПОСЕЩАЕМОСТИ ВОСПИТАННИКОВ"];
      sheet.getRow(5).values = [
        `за ${moment(this.currentDay).format("MMMM, YYYY")}`
      ];
      sheet.getRow(6).values = ["", "Учреждение", `${this.KindergartenName}`];
      sheet.getRow(7).values = ["", "Структура подразделения", ""];
      sheet.getRow(8).values = [
        "",
        "Вид расчета",
        "Плата за питание (общая усл.)"
      ];
      sheet.getRow(9).values = [""];

      sheet.getCell(`${self.fetchExelCols(maxCol)}3`).value = "Коды";
      sheet.getCell(`${self.fetchExelCols(maxCol - 2)}4`).value =
        "Форма по ОКУД";
      sheet.getCell(`${self.fetchExelCols(maxCol)}4`).value = "0504608";
      sheet.getCell(`${self.fetchExelCols(maxCol - 2)}5`).value = "Дата";
      sheet.getCell(`${self.fetchExelCols(maxCol)}5`).value = moment(
        this.currentDay
      ).format("DD.MM.YYYY");
      sheet.getCell(`${self.fetchExelCols(maxCol - 2)}6`).value = "ПО ОКПО";
      sheet.getCell(`${self.fetchExelCols(maxCol)}6`).value = "37448296";

      const beforeDayColumns = [
        {
          key: "№",
          width: 5
        },
        {
          key: "fullName",
          width: 30
        },
        {
          key: "numberAccount",
          width: 10
        },
        {
          key: "payment",
          width: 10
        }
      ];

      const dayColumns = days.map(function(el) {
        return {
          key: el.numbers,
          width: 5
        };
      });

      const afterDayColumns = [
        {
          key: "all", // всего
          width: 8
        },
        {
          key: "withCount", // в том числе засчитываемых
          width: 10
        },
        {
          key: "dayToPays", // дни/часы подлежащие оплате
          width: 15
        },
        {
          key: "reasonsAbsence", // причины непосещения
          width: 20
        }
      ];

      const resultColumns = [
        ...beforeDayColumns,
        ...dayColumns,
        ...afterDayColumns
      ];
      sheet.columns = resultColumns;

      const beforeTitleColumnsData = {
        "№": "№",
        fullName: "Воспитанник",
        numberAccount: "Номер счета",
        payment: "Плата по ставке"
      };
      const titleDataColumnsData = {};
      const titleWeekColumnsData = {};
      for (let i = 0; i < days.length; i++) {
        const day = days[i];
        const dataDay = day.numbers;
        const index = i + 1;
        titleDataColumnsData[`${dataDay}`] = index;
        titleWeekColumnsData[`${dataDay}`] = day.week;
      }
      const afterTitleColumnsData = {
        all: "Всего",
        withCount: "В том числе засчитываемых",
        dayToPays: "Дни/часы посещения, подлежащие оплате",
        reasonsAbsence: "Причины непосещения (основание)"
      };

      const resultTitleColumns = {
        ...beforeTitleColumnsData,
        ...titleDataColumnsData,
        ...afterTitleColumnsData
      };

      const resultWeekTitleColumns = {
        ...beforeTitleColumnsData,
        ...titleWeekColumnsData,
        ...afterTitleColumnsData
      };
      sheet.addRows([resultTitleColumns]);
      sheet.addRows([resultTitleColumns]);
      sheet.addRows([resultWeekTitleColumns]);

      const dayOffs = {};
      for (let i = 0; i < days.length; i++) {
        const day = days[i];
        if (day.week == "сб" || day.week == "вс") {
          const dataDay = day.numbers;
          dayOffs[`${dataDay}`] = "П";
        }
      }

      const columnsData = _.map(this.childTableData, function(el, index) {
        const visits = {};
        if (el.visits.length > 0) {
          for (let i = 0; i < el.visits.length; i++) {
            const visit = el.visits[i];
            const visitDay = visit.date;
            visits[`${visitDay}`] = self.marks[`${visit.status}`];
          }
        }
        return {
          "№": index,
          fullName: el.child.name,
          ...visits,
          ...dayOffs
        };
      });

      sheet.addRows(columnsData);

      //Стили
      sheet.getRow(1).alignment = {
        vertical: "middle",
        horizontal: "right"
      };
      [3, 4, 5].map(
        x =>
          (sheet.getRow(x).alignment = {
            vertical: "middle",
            horizontal: "center"
          })
      );
      [3, 4].map(x => (sheet.getRow(x).font = { size: 16, bold: true }));

      [
        `${self.fetchExelCols(maxCol)}3`,
        `${self.fetchExelCols(maxCol - 2)}4`,
        `${self.fetchExelCols(maxCol)}4`,
        `${self.fetchExelCols(maxCol - 2)}5`,
        `${self.fetchExelCols(maxCol)}5`,
        `${self.fetchExelCols(maxCol - 2)}6`,
        `${self.fetchExelCols(maxCol)}6`
      ].map(
        x =>
          (sheet.getCell(x).font = {
            size: 11,
            bold: false
          })
      );

      ["C6", "C7", "C8"].map(
        x =>
          (sheet.getCell(x).border = {
            bottom: { style: "thin" }
          })
      );

      [
        `${self.fetchExelCols(maxCol - 2)}4`,
        `${self.fetchExelCols(maxCol - 2)}5`,
        `${self.fetchExelCols(maxCol - 2)}6`
      ].map(
        x =>
          (sheet.getCell(x).alignment = {
            vertical: "middle",
            horizontal: "right"
          })
      );

      [
        `${self.fetchExelCols(maxCol)}3`,
        `${self.fetchExelCols(maxCol - 2)}4`,
        `${self.fetchExelCols(maxCol)}4`,
        `${self.fetchExelCols(maxCol - 2)}5`,
        `${self.fetchExelCols(maxCol)}5`,
        `${self.fetchExelCols(maxCol - 2)}6`,
        `${self.fetchExelCols(maxCol)}6`
      ].map(
        x =>
          (sheet.getCell(x).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
          })
      );

      [
        `${self.fetchExelCols(maxCol)}6`,
        `A10`,
        `B10`,
        `C10`,
        `D10`,
        `E10`,
        `${self.fetchExelCols(maxCol - 3)}10`,
        `${self.fetchExelCols(maxCol - 3)}11`,
        `${self.fetchExelCols(maxCol - 2)}11`,
        `${self.fetchExelCols(maxCol - 1)}10`,
        `${self.fetchExelCols(maxCol)}10`
      ].map(
        x =>
          (sheet.getCell(x).alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: true
          })
      );

      sheet.getCell("E10").value = "Дни посещения";
      sheet.getCell(`${self.fetchExelCols(maxCol - 3)}10`).value =
        "Пропущено дней/часов";

      // объединяем ячейки
      sheet.mergeCells(`A1:${self.fetchExelCols(maxCol)}1`);
      sheet.mergeCells(`A3:${self.fetchExelCols(maxCol - 4)}3`);
      sheet.mergeCells(`A4:${self.fetchExelCols(maxCol - 4)}4`);
      sheet.mergeCells(`A5:${self.fetchExelCols(maxCol - 4)}5`);
      sheet.mergeCells(`C6:${self.fetchExelCols(maxCol - 4)}6`);
      sheet.mergeCells(`C7:${self.fetchExelCols(maxCol - 4)}7`);
      sheet.mergeCells(`C8:${self.fetchExelCols(maxCol - 4)}8`);
      sheet.mergeCells(
        `${self.fetchExelCols(maxCol - 3)}10:${self.fetchExelCols(
          maxCol - 2
        )}10`
      );
      sheet.mergeCells(
        `${self.fetchExelCols(maxCol - 2)}4:${self.fetchExelCols(maxCol - 1)}4`
      );
      sheet.mergeCells(
        `${self.fetchExelCols(maxCol - 2)}5:${self.fetchExelCols(maxCol - 1)}5`
      );
      sheet.mergeCells(
        `${self.fetchExelCols(maxCol - 2)}6:${self.fetchExelCols(maxCol - 1)}6`
      );
      sheet.mergeCells(`A10:A12`);
      sheet.mergeCells(`B10:B12`);
      sheet.mergeCells(`C10:C12`);
      sheet.mergeCells(`D10:D12`);
      // sheet.mergeCells(`${self.fetchExelCols(maxCol-4)}10:${self.fetchExelCols(maxCol-4)}12`);
      sheet.mergeCells(
        `${self.fetchExelCols(maxCol - 3)}11:${self.fetchExelCols(
          maxCol - 3
        )}12`
      );
      sheet.mergeCells(
        `${self.fetchExelCols(maxCol - 2)}11:${self.fetchExelCols(
          maxCol - 2
        )}12`
      );
      sheet.mergeCells(
        `${self.fetchExelCols(maxCol - 1)}10:${self.fetchExelCols(
          maxCol - 1
        )}12`
      );
      sheet.mergeCells(
        `${self.fetchExelCols(maxCol)}10:${self.fetchExelCols(maxCol)}12`
      );
      sheet.mergeCells(`E10:${self.fetchExelCols(maxCol - 4)}10`);
      // sheet.mergeCells(`E11:AH11`);

      workbook.xlsx
        .writeBuffer()
        .then(function(buffer) {
          const blob = new Blob([buffer], { type: "applicationi/xlsx" });
          saveAs(blob, "myexcel.xlsx");
        })
        .then(() => {
          this.loadingBtn = false;
        });
    },

    clearVisitLog() {
      this.toggleHandler = false;
      // router.go(-1);
    },

    fetchExelCols(val) {
      return exelCols[`${val}`];
    },

    submitVisited() {
      this.$store
        .dispatch("editChildrenVisitLog")
        .finally(() => (this.toggleHandler = false));
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: this.urlQuery.query
    });
    this.$store.dispatch("fetchKindergartensInfo");
  }
};
</script>

<style scoped>
.table_border {
  width: 150px;
  height: 30px;
  border: 1px solid #000000;
}
.textField {
  width: 1100px;
}
.headText {
  margin-left: 250px;
  margin-right: 10px;
}
</style>
